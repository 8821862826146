



































.breadcrumb {
  padding: 5px 28px;
  background-color: #f5f2f2;

  ul {
    list-style: none;
    display: flex;
    padding-left: 0;

    li {
      font-size: .9em;
      opacity: .7;
    }
  }

  ul > li:not(:last-child)::after {
    content: '/';
    float: right;
    font-size: 1em;
    margin: 0 .5em;
    cursor: default;
  }

  .linked {
    cursor: pointer;
    font-weight: normal;
    opacity: 1;
  }
}
